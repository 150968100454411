import type { DeviceData } from "@commerce-frontend/gtm-ecommerce";
import { matchBreakpoint } from "~/hooks/useBreakpoint";

export const deviceData = (): DeviceData => {
	let device: DeviceData["device"] = "mobile";
	if (matchBreakpoint("lg").matches) {
		device = "desktop";
	} else if (matchBreakpoint("md").matches) {
		device = "tablet";
	}

	return {
		screen_width: window.screen.width,
		screen_height: window.screen.height,
		user_agent: window.navigator.userAgent,
		device,
	};
};
