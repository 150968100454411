import type { EcommerceData, EcommerceItemData } from "@commerce-frontend/gtm-ecommerce";
import {
	type CategoryAnalyticsFragmentFragment,
	type CheckoutSummaryFragmentFragment,
	type CombinedProductAnalyticsFragmentFragment,
	type FailedOrderDetailFragment,
	type OrderDetailFragment,
	graphql,
} from "@commerce-frontend/types";

graphql(/* GraphQL */ `
	fragment ProductVariantAnalyticsFragment on ProductVariant {
		name
		sku
		regularPrice {
			gross {
				...Price
			}
		}
		attributes {
			name
			value
		}
	}

	fragment CategoryAnalyticsFragment on ProductCategory {
		name
		id
		ancestors {
			storyblokName
			name
		}
	}

	fragment ProductAnalyticsFragment on Product {
		id
		brand
		name
	}

	fragment CombinedProductAnalyticsFragment on Product {
		...ProductAnalyticsFragment

		primaryCategory {
			...CategoryAnalyticsFragment
		}

		primaryVariant {
			...ProductVariantAnalyticsFragment
		}

		variants {
			...ProductVariantAnalyticsFragment
		}
	}
`);

export const ecommerceItemData = (
	product: CombinedProductAnalyticsFragmentFragment,
	position: number,
	quantity = 1,
	sku?: string,
): EcommerceItemData => {
	const variant =
		(sku ? product.variants.find((variant) => variant.sku === sku) : null) ??
		product.primaryVariant;
	const ancestors = product.primaryCategory?.ancestors;

	return {
		...getProductCategory(ancestors),
		item_id: product.id,
		item_brand: product.brand ?? "Zeeman",
		item_name: product.name,
		item_size: variant.attributes?.find((a) => a.name === "size")?.value ?? null,
		item_variant:
			variant.attributes?.find((a) => a.name === "color")?.value ?? variant.name ?? null,
		item_quantity: quantity,
		item_price: (variant.regularPrice?.gross.centAmount ?? 0) / 100,
		item_position: position,
	};
};

export const ecommerceData = (
	products: {
		product: CombinedProductAnalyticsFragmentFragment;
		sku?: string;
		quantity?: number;
	}[],
): EcommerceData => {
	return rawEcommerceData(
		products.reduce((sum, { product, sku, quantity }) => {
			const variant =
				(sku ? product.variants.find((variant) => variant.sku === sku) : null) ??
				product.primaryVariant;

			return sum + (variant.regularPrice?.gross.centAmount ?? 0) * (quantity ?? 1);
		}, 0) / 100,
		products.map(({ product, sku, quantity }, index) =>
			ecommerceItemData(product, index + 1, quantity ?? 1, sku),
		),
	);
};

export const ecommerceDataFromCheckout = (
	checkoutSummary: CheckoutSummaryFragmentFragment,
): EcommerceData =>
	rawEcommerceData(
		checkoutSummary.lineItems.reduce(
			(sum, lineItem) => sum + lineItem.price.gross.centAmount / 100,
			0,
		),
		checkoutSummary.lineItems.map((lineItem) => {
			const categories = lineItem.category?.ancestors;

			return {
				...getProductCategory(categories),
				item_brand: "Zeeman",
				item_id: lineItem.productId,
				item_name: lineItem.productName,
				item_position: null,
				item_price: lineItem.price.gross.centAmount / lineItem.quantity / 100,
				item_quantity: lineItem.quantity,
				item_size: lineItem.variant.size ?? null,
				item_variant: lineItem.variant.color ?? null,
			};
		}),
		null,
		typeof checkoutSummary.shippingCosts?.centAmount === "number"
			? checkoutSummary.shippingCosts?.centAmount / 100
			: null,
	);

export const ecommerceDataFromOrder = (
	order: FailedOrderDetailFragment | OrderDetailFragment,
): EcommerceData =>
	rawEcommerceData(
		order.lineItems.reduce((sum, lineItem) => sum + lineItem.price.gross.centAmount / 100, 0),
		order.lineItems.map((lineItem) => {
			const categories = lineItem.category?.ancestors;

			return {
				...getProductCategory(categories),
				item_brand: "Zeeman",
				item_id: lineItem.productId,
				item_name: lineItem.productName,
				item_position: null,
				item_price: lineItem.price.gross.centAmount / lineItem.quantity / 100,
				item_quantity: lineItem.quantity,
				item_size: lineItem.variant.size ?? null,
				item_variant: lineItem.variant.color ?? null,
			};
		}),
		order.orderNumber,
		typeof order.shippingCosts?.centAmount === "number"
			? order.shippingCosts?.centAmount / 100
			: null,
	);

export const rawEcommerceData = (
	value: number,
	items: EcommerceItemData[],
	transactionId?: string | number | null,
	deliveryCost?: number | null,
): EcommerceData => ({
	currency: "EUR",
	transaction_id: transactionId || null,
	delivery_cost: deliveryCost || null,
	value,
	items,
});

export const getProductCategory = (
	ancestors: CategoryAnalyticsFragmentFragment["ancestors"] | undefined,
): Pick<EcommerceItemData, "item_category" | "item_subcategory"> => {
	return {
		item_category: ancestors?.at(-1)?.storyblokName ?? ancestors?.at(-1)?.name ?? null,
		item_subcategory: ancestors?.length
			? ancestors?.slice(0, -1).map((subcategory) => subcategory.storyblokName ?? subcategory?.name)
			: null,
	};
};
