import type { Config } from "tailwindcss";
import { screens } from "tailwindcss/defaultTheme";

export const BREAKPOINTS = screens;

export default {
	content: [
		"./src/**/*.{js,ts,jsx,tsx,mdx}",
		"../../site/src/app/**/*.{js,ts,jsx,tsx,mdx}",
		"../../site/src/components/**/*.{js,ts,jsx,tsx,mdx}",
	],
	darkMode: ["class"],
	theme: {
		container: {
			center: true,
			padding: "2rem",
			screens: {
				"2xl": "1280px",
			},
		},
		extend: {
			typography: () => ({
				gray: {
					css: {
						"--tw-prose-body": "hsl(var(--gray-600))",
						fontSize: "1.25rem",
						lineHeight: "1.875rem",
					},
				},
			}),
			colors: {
				brand: {
					DEFAULT: "hsl(var(--brand-500) / <alpha-value>)",
					50: "hsl(var(--brand-50) / <alpha-value>)",
					100: "hsl(var(--brand-100) / <alpha-value>)",
					200: "hsl(var(--brand-200) / <alpha-value>)",
					300: "hsl(var(--brand-300) / <alpha-value>)",
					400: "hsl(var(--brand-400) / <alpha-value>)",
					500: "hsl(var(--brand-500) / <alpha-value>)",
					600: "hsl(var(--brand-600) / <alpha-value>)",
					700: "hsl(var(--brand-700) / <alpha-value>)",
					800: "hsl(var(--brand-800) / <alpha-value>)",
					900: "hsl(var(--brand-900) / <alpha-value>)",
					950: "hsl(var(--brand-950) / <alpha-value>)",
				},
				gray: {
					25: "hsl(var(--gray-25) / <alpha-value>)",
					50: "hsl(var(--gray-50) / <alpha-value>)",
					100: "hsl(var(--gray-100) / <alpha-value>)",
					200: "hsl(var(--gray-200) / <alpha-value>)",
					300: "hsl(var(--gray-300) / <alpha-value>)",
					400: "hsl(var(--gray-400) / <alpha-value>)",
					500: "hsl(var(--gray-500) / <alpha-value>)",
					600: "hsl(var(--gray-600) / <alpha-value>)",
					700: "hsl(var(--gray-700) / <alpha-value>)",
					800: "hsl(var(--gray-800) / <alpha-value>)",
					900: "hsl(var(--gray-900) / <alpha-value>)",
				},
				"gray-blue": {
					50: "hsl(var(--gray-blue-50) / <alpha-value>)",
					100: "hsl(var(--gray-blue-100) / <alpha-value>)",
					200: "hsl(var(--gray-blue-200) / <alpha-value>)",
					300: "hsl(var(--gray-blue-300) / <alpha-value>)",
					400: "hsl(var(--gray-blue-400) / <alpha-value>)",
					500: "hsl(var(--gray-blue-500) / <alpha-value>)",
					600: "hsl(var(--gray-blue-600) / <alpha-value>)",
					700: "hsl(var(--gray-blue-700) / <alpha-value>)",
					800: "hsl(var(--gray-blue-800) / <alpha-value>)",
					900: "hsl(var(--gray-blue-900) / <alpha-value>)",
					950: "hsl(var(--gray-blue-950) / <alpha-value>)",
				},
				"blue-light": {
					50: "hsl(var(--blue-light-50) / <alpha-value>)",
					100: "hsl(var(--blue-light-100) / <alpha-value>)",
					200: "hsl(var(--blue-light-200) / <alpha-value>)",
					300: "hsl(var(--blue-light-300) / <alpha-value>)",
					400: "hsl(var(--blue-light-400) / <alpha-value>)",
					500: "hsl(var(--blue-light-500) / <alpha-value>)",
					600: "hsl(var(--blue-light-600) / <alpha-value>)",
					700: "hsl(var(--blue-light-700) / <alpha-value>)",
					800: "hsl(var(--blue-light-800) / <alpha-value>)",
					900: "hsl(var(--blue-light-900) / <alpha-value>)",
					950: "hsl(var(--blue-light-950) / <alpha-value>)",
				},
				blue: {
					50: "hsl(var(--blue-50) / <alpha-value>)",
					100: "hsl(var(--blue-100) / <alpha-value>)",
					200: "hsl(var(--blue-200) / <alpha-value>)",
					300: "hsl(var(--blue-300) / <alpha-value>)",
					400: "hsl(var(--blue-400) / <alpha-value>)",
					500: "hsl(var(--blue-500) / <alpha-value>)",
					600: "hsl(var(--blue-600) / <alpha-value>)",
					700: "hsl(var(--blue-700) / <alpha-value>)",
					800: "hsl(var(--blue-800) / <alpha-value>)",
					900: "hsl(var(--blue-900) / <alpha-value>)",
					950: "hsl(var(--blue-950) / <alpha-value>)",
				},
				"blue-dark": {
					50: "hsl(var(--blue-dark-50) / <alpha-value>)",
					100: "hsl(var(--blue-dark-100) / <alpha-value>)",
					200: "hsl(var(--blue-dark-200) / <alpha-value>)",
					300: "hsl(var(--blue-dark-300) / <alpha-value>)",
					400: "hsl(var(--blue-dark-400) / <alpha-value>)",
					500: "hsl(var(--blue-dark-500) / <alpha-value>)",
					600: "hsl(var(--blue-dark-600) / <alpha-value>)",
					700: "hsl(var(--blue-dark-700) / <alpha-value>)",
					800: "hsl(var(--blue-dark-800) / <alpha-value>)",
					900: "hsl(var(--blue-dark-900) / <alpha-value>)",
					950: "hsl(var(--blue-dark-950) / <alpha-value>)",
				},
				indigo: {
					50: "hsl(var(--indigo-50) / <alpha-value>)",
					100: "hsl(var(--indigo-100) / <alpha-value>)",
					200: "hsl(var(--indigo-200) / <alpha-value>)",
					300: "hsl(var(--indigo-300) / <alpha-value>)",
					400: "hsl(var(--indigo-400) / <alpha-value>)",
					500: "hsl(var(--indigo-500) / <alpha-value>)",
					600: "hsl(var(--indigo-600) / <alpha-value>)",
					700: "hsl(var(--indigo-700) / <alpha-value>)",
					800: "hsl(var(--indigo-800) / <alpha-value>)",
					900: "hsl(var(--indigo-900) / <alpha-value>)",
					950: "hsl(var(--indigo-950) / <alpha-value>)",
				},
				purple: {
					50: "hsl(var(--purple-50) / <alpha-value>)",
					100: "hsl(var(--purple-100) / <alpha-value>)",
					200: "hsl(var(--purple-200) / <alpha-value>)",
					300: "hsl(var(--purple-300) / <alpha-value>)",
					400: "hsl(var(--purple-400) / <alpha-value>)",
					500: "hsl(var(--purple-500) / <alpha-value>)",
					600: "hsl(var(--purple-600) / <alpha-value>)",
					700: "hsl(var(--purple-700) / <alpha-value>)",
					800: "hsl(var(--purple-800) / <alpha-value>)",
					900: "hsl(var(--purple-900) / <alpha-value>)",
					950: "hsl(var(--purple-950) / <alpha-value>)",
				},
				fuchsia: {
					50: "hsl(var(--fuchsia-50) / <alpha-value>)",
					100: "hsl(var(--fuchsia-100) / <alpha-value>)",
					200: "hsl(var(--fuchsia-200) / <alpha-value>)",
					300: "hsl(var(--fuchsia-300) / <alpha-value>)",
					400: "hsl(var(--fuchsia-400) / <alpha-value>)",
					500: "hsl(var(--fuchsia-500) / <alpha-value>)",
					600: "hsl(var(--fuchsia-600) / <alpha-value>)",
					700: "hsl(var(--fuchsia-700) / <alpha-value>)",
					800: "hsl(var(--fuchsia-800) / <alpha-value>)",
					900: "hsl(var(--fuchsia-900) / <alpha-value>)",
					950: "hsl(var(--fuchsia-950) / <alpha-value>)",
				},
				pink: {
					50: "hsl(var(--pink-50) / <alpha-value>)",
					100: "hsl(var(--pink-100) / <alpha-value>)",
					200: "hsl(var(--pink-200) / <alpha-value>)",
					300: "hsl(var(--pink-300) / <alpha-value>)",
					400: "hsl(var(--pink-400) / <alpha-value>)",
					500: "hsl(var(--pink-500) / <alpha-value>)",
					600: "hsl(var(--pink-600) / <alpha-value>)",
					700: "hsl(var(--pink-700) / <alpha-value>)",
					800: "hsl(var(--pink-800) / <alpha-value>)",
					900: "hsl(var(--pink-900) / <alpha-value>)",
					950: "hsl(var(--pink-950) / <alpha-value>)",
				},
				orange: {
					50: "hsl(var(--orange-50) / <alpha-value>)",
					100: "hsl(var(--orange-100) / <alpha-value>)",
					200: "hsl(var(--orange-200) / <alpha-value>)",
					300: "hsl(var(--orange-300) / <alpha-value>)",
					400: "hsl(var(--orange-400) / <alpha-value>)",
					500: "hsl(var(--orange-500) / <alpha-value>)",
					600: "hsl(var(--orange-600) / <alpha-value>)",
					700: "hsl(var(--orange-700) / <alpha-value>)",
					800: "hsl(var(--orange-800) / <alpha-value>)",
					900: "hsl(var(--orange-900) / <alpha-value>)",
					950: "hsl(var(--orange-950) / <alpha-value>)",
				},
				"orange-dark": {
					50: "hsl(var(--orange-dark-50) / <alpha-value>)",
					100: "hsl(var(--orange-dark-100) / <alpha-value>)",
					200: "hsl(var(--orange-dark-200) / <alpha-value>)",
					300: "hsl(var(--orange-dark-300) / <alpha-value>)",
					400: "hsl(var(--orange-dark-400) / <alpha-value>)",
					500: "hsl(var(--orange-dark-500) / <alpha-value>)",
					600: "hsl(var(--orange-dark-600) / <alpha-value>)",
					700: "hsl(var(--orange-dark-700) / <alpha-value>)",
					800: "hsl(var(--orange-dark-800) / <alpha-value>)",
					900: "hsl(var(--orange-dark-900) / <alpha-value>)",
					950: "hsl(var(--orange-dark-950) / <alpha-value>)",
				},
				error: {
					50: "hsl(var(--error-50) / <alpha-value>)",
					100: "hsl(var(--error-100) / <alpha-value>)",
					200: "hsl(var(--error-200) / <alpha-value>)",
					300: "hsl(var(--error-300) / <alpha-value>)",
					400: "hsl(var(--error-400) / <alpha-value>)",
					500: "hsl(var(--error-500) / <alpha-value>)",
					600: "hsl(var(--error-600) / <alpha-value>)",
					700: "hsl(var(--error-700) / <alpha-value>)",
					800: "hsl(var(--error-800) / <alpha-value>)",
					900: "hsl(var(--error-900) / <alpha-value>)",
					950: "hsl(var(--error-950) / <alpha-value>)",
				},
				warning: {
					50: "hsl(var(--warning-50) / <alpha-value>)",
					100: "hsl(var(--warning-100) / <alpha-value>)",
					200: "hsl(var(--warning-200) / <alpha-value>)",
					300: "hsl(var(--warning-300) / <alpha-value>)",
					400: "hsl(var(--warning-400) / <alpha-value>)",
					500: "hsl(var(--warning-500) / <alpha-value>)",
					600: "hsl(var(--warning-600) / <alpha-value>)",
					700: "hsl(var(--warning-700) / <alpha-value>)",
					800: "hsl(var(--warning-800) / <alpha-value>)",
					900: "hsl(var(--warning-900) / <alpha-value>)",
					950: "hsl(var(--warning-950) / <alpha-value>)",
				},
				success: {
					50: "hsl(var(--success-50) / <alpha-value>)",
					100: "hsl(var(--success-100) / <alpha-value>)",
					200: "hsl(var(--success-200) / <alpha-value>)",
					300: "hsl(var(--success-300) / <alpha-value>)",
					400: "hsl(var(--success-400) / <alpha-value>)",
					500: "hsl(var(--success-500) / <alpha-value>)",
					600: "hsl(var(--success-600) / <alpha-value>)",
					700: "hsl(var(--success-700) / <alpha-value>)",
					800: "hsl(var(--success-800) / <alpha-value>)",
					900: "hsl(var(--success-900) / <alpha-value>)",
					950: "hsl(var(--success-950) / <alpha-value>)",
				},
			},
			boxShadow: {
				0: "var(--shadow-level-0)",
				1: "var(--shadow-level-1)",
				2: "var(--shadow-level-2)",
				3: "var(--shadow-level-3)",
				4: "var(--shadow-level-4)",
				5: "var(--shadow-level-5)",
				step: "0 35px 60px -15px var(--success-300)",
			},
			borderRadius: {
				none: "0",
				xxs: "0.125rem",
				xs: "0.25rem",
				sm: "0.375rem",
				md: "0.5rem",
				lg: "0.625rem",
				xl: "0.75rem",
				"2xl": "1rem",
				"3xl": "1.25rem",
				"4xl": "1.5rem",
			},
			spacing: {
				xxs: "0.125rem",
				xs: "0.25rem",
				sm: "0.375rem",
				md: "0.5rem",
				lg: "0.75rem",
				xl: "1rem",
				"2xl": "1.25rem",
				"3xl": "1.5rem",
				"4xl": "2rem",
				"5xl": "2.5rem",
				"6xl": "3rem",
				"7xl": "4rem",
				"8xl": "5rem",
				"9xl": "6rem",
				"10xl": "8rem",
				"11xl": "10rem",
				column: "25rem",
			},
			fontSize: {
				xs: ["0.75rem", "1rem"],
				sm: ["0.875rem", "1.25rem"],
				md: ["1rem", "1.5rem"],
				lg: ["1.125rem", "1.755rem"],
				xl: ["1.25rem", "1.875rem"],
				"2xl": ["1.5rem", "2rem"],
				"3xl": ["1.875rem", "2.375rem"],
				"4xl": [
					"2.25rem",
					{
						lineHeight: "2.75rem",
						letterSpacing: "-0.045em",
					},
				],
				"5xl": [
					"3rem",
					{
						lineHeight: "3.75rem",
						letterSpacing: "-0.06em",
					},
				],
				"6xl": [
					"3.75rem",
					{
						lineHeight: "4.5rem",
						letterSpacing: "-0.075em",
					},
				],
				"7xl": [
					"4.5rem",
					{
						lineHeight: "5.625rem",
						letterSpacing: "-0.09em",
					},
				],
			},
			fontFamily: {
				sans: ["var(--font-inter), sans-serif"],
				texGyreHeros: ["var(--font-tex-gyre-heros), sans-serif"],
			},
			keyframes: {
				"accordion-down": {
					from: { height: "0" },
					to: { height: "var(--radix-accordion-content-height)" },
				},
				"accordion-up": {
					from: { height: "var(--radix-accordion-content-height)" },
					to: { height: "0" },
				},
			},
			animation: {
				"accordion-down": "accordion-down 0.2s ease-out",
				"accordion-up": "accordion-up 0.2s ease-out",
			},
			backgroundImage: {
				diagonalStrikethrough: "var(--diagonal-strikethrough)",
				strikethrough: "var(--strikethrough)",
			},
			margin: {
				container: "2em",
				"container-sm": "1em",
			},
			padding: {
				container: "2em",
				"container-sm": "1em",
			},
			maxWidth: {
				container: "1280px",
			},
			transitionDuration: {
				"800": "800ms",
			},
			screens: {
				xxs: "375px",
				"3xl": "1400px",
				"4xl": "1500px",
				menuDesktop: "1100px",
				menuMobile: "420px",
			},
			aspectRatio: {
				"4/5": "4 / 5",
				"16/9": "16 / 9",
			},
		},
	},
	plugins: [
		require("@tailwindcss/typography"),
		require("tailwindcss-animate"),
		require("@tailwindcss/container-queries"),
	],
} satisfies Config;
