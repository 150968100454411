import type { SearchData } from "@commerce-frontend/gtm-ecommerce";

export const searchData = (query: string): SearchData => ({
	search_term: query,
	suggestion_count: null,
	suggestion_select: {
		suggestion_select_position: null,
		suggestion_select_category: null,
		suggestion_select_name: null,
		suggestion_select_id: null,
	},
	suggestion_group: null,
});
