import { useSyncExternalStore } from "react";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config";

const fullConfig = resolveConfig(tailwindConfig);

export type Breakpoint = keyof typeof fullConfig.theme.screens;

export const matchBreakpoint = (breakpoint: Breakpoint | number): MediaQueryList => {
	return window.matchMedia(
		`(min-width: ${typeof breakpoint === "string" ? fullConfig.theme.screens[breakpoint] : `${breakpoint}px`})`,
	);
};

/**
 * Observes the screen size using the specified breakpoint, tringgering a re-render when the specified breakpoint becomes active or inactive.
 * For a full list of breakpoints, see {@link https://tailwindcss.com/docs/responsive-design the Tailwind documentation on responsive design}.
 * @param {Breakpoint} breakpoint - The breakpoint to query
 * @returns True if the screen width is larger or equal to the specified breakpoint, false otherwise
 */
export const useBreakpoint = (breakpoint: Breakpoint | number): boolean | undefined =>
	useSyncExternalStore(
		(listener) => {
			const match = matchBreakpoint(breakpoint);

			match.addEventListener("change", listener);
			return () => {
				match.removeEventListener("change", listener);
			};
		},
		() => {
			const match = matchBreakpoint(breakpoint);
			return match.matches;
		},
		() => false,
	);
