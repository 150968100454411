import type { LocationData, PageSubtype, PageType } from "@commerce-frontend/gtm-ecommerce";
import { prefixForLocale, type usePathname } from "@commerce-frontend/i18n/navigation";
import type { Locale } from "@commerce-frontend/i18n/types";

const getPageType = (
	// content paths are unspecified in the routing dictionary, so add a string catch-all
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	pathname: ReturnType<typeof usePathname> | string,
): Pick<LocationData, "page_type" | "page_subtype"> => {
	switch (pathname) {
		case "/":
			return {
				page_type: "home",
				page_subtype: null,
			};

		case "/search":
			return {
				page_type: "search",
				page_subtype: "searchresults",
			};

		case "/product/[slug]":
		case "/products/[slug]":
			return {
				page_type: "category",
				page_subtype: "productdetail",
			};

		case "/account":
		case "/account/address":
		case "/account/login":
		case "/account/orders":
		case "/account/orders/[id]":
		case "/account/password-reset":
		case "/account/password-reset/success":
		case "/account/profile":
		case "/account/profile/billing-address":
		case "/account/profile/credentials":
		case "/account/register":
		case "/account/register/success":
			return {
				page_type: "service",
				page_subtype: "account",
			};

		case "/cart":
			return {
				page_type: "cart",
				page_subtype: null,
			};

		case "/checkout/confirmation/[orderId]":
			return {
				page_type: "checkout",
				page_subtype: "success",
			};

		case "/checkout/delivery":
			return {
				page_type: "checkout",
				page_subtype: "delivery",
			};

		case "/checkout/information":
			return {
				page_type: "checkout",
				page_subtype: "information",
			};

		case "/checkout/payment":
			return {
				page_type: "checkout",
				page_subtype: "payment",
			};

		case "/checkout/payment/[orderId]":
			return {
				page_type: "checkout",
				page_subtype: "payment_failed",
			};

		case "/checkout":
		case "/checkout/login":
			return {
				page_type: "checkout",
				page_subtype: "login",
			};

		case "/faqs":
		case "/faqs/[slug]":
			return {
				page_type: "service",
				page_subtype: "faq",
			};

		case "/stores":
			return {
				page_type: "store",
				page_subtype: "overview",
			};

		case "/stores/[slug]":
			return {
				page_type: "store",
				page_subtype: "store_detail",
			};
	}

	// content pages are considered directionals, their depth is determined by their placement in the content tree
	return {
		page_type: "category",
		page_subtype: `directional_${pathname.split("/").length - 2}`,
	};
};

export const getCategoryPathFromSession = (): string[] => {
	if (typeof window !== "undefined") {
		const sessionCategoryPath = sessionStorage?.getItem("categoryPath");
		return sessionCategoryPath ? (JSON.parse(sessionCategoryPath) as string[]) : [];
	}

	return [];
};

export const getPathWithCurrentCategory = (
	categoryId: string | undefined,
	categoryPath: string[],
): string[] => {
	const MAX_PATH_LENGTH = 20;

	if (categoryId && categoryPath[categoryPath.length - 1] !== categoryId) {
		return [...categoryPath, categoryId].slice(-MAX_PATH_LENGTH);
	}

	return categoryPath;
};

export type LocationProps<T extends PageType = PageType> = {
	locale: Locale;
	pageType: T;
	pageSubtype: PageSubtype<T>;
	productId?: string;
	categoryId?: string;
	categoryPath?: string[];
	previousUrl?: string | null;
};

export const locationData = <T extends PageType = PageType>({
	locale,
	pageType,
	pageSubtype,
	productId,
	categoryId,
	categoryPath,
	previousUrl,
}: LocationProps<T>): LocationData<T> => {
	const sessionCategoryPath = getCategoryPathFromSession();
	const pathWithCurrentCategoryId = getPathWithCurrentCategory(categoryId, sessionCategoryPath);

	return {
		page_type: pageType || null,
		page_subtype: pageSubtype || null,
		page_title: document.title || null,
		page_url: window.location.href || null,
		page_path: window.location.pathname || null,
		query: window.location.search || null,
		hostname: window.location.hostname || null,
		country: prefixForLocale(locale) || null,
		referrer: document.referrer || previousUrl || null,
		product_id: productId || null,
		category_id: categoryId || null,
		category_path: categoryPath || pathWithCurrentCategoryId || null,
	};
};

export type PathLocationProps<T extends PageType = PageType> = {
	locale: Locale;
	pathname: ReturnType<typeof usePathname>;
	productId?: string;
	categoryId?: string;
	categoryPath?: string[];
	previousUrl?: string | null;
	pageType?: T;
	pageSubtype?: PageSubtype<T>;
};

export const locationDataFromPathname = ({
	locale,
	pathname,
	productId,
	categoryId,
	categoryPath,
	previousUrl,
	pageType,
	pageSubtype,
}: PathLocationProps): LocationData => {
	const sessionCategoryPath = getCategoryPathFromSession();
	const pathWithCurrentCategoryId = getPathWithCurrentCategory(categoryId, sessionCategoryPath);
	const pageTypes =
		pageType && pageSubtype
			? { page_type: pageType, page_subtype: pageSubtype }
			: getPageType(pathname);

	return {
		...pageTypes,
		page_title: document.title || null,
		page_url: window.location.href || null,
		page_path: window.location.pathname || null,
		query: window.location.search || null,
		hostname: window.location.hostname || null,
		country: prefixForLocale(locale) || null,
		referrer: document.referrer || previousUrl || null,
		product_id: productId || null,
		category_id: categoryId || null,
		category_path: categoryPath || pathWithCurrentCategoryId || null,
	};
};
