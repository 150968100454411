import type { Config } from "tailwindcss";

import uiConfig from "@commerce-frontend/ui/tailwind.config";
export default {
	...uiConfig,
	content: [
		"../packages/ui/src/**/*.{js,ts,jsx,tsx,mdx}",
		"./src/pages/**/*.{js,ts,jsx,tsx,mdx}",
		"./src/app/**/*.{js,ts,jsx,tsx,mdx}",
		"./src/components/**/*.{js,ts,jsx,tsx,mdx}",
	],
} satisfies Config;
