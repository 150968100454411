import type { UserData } from "@commerce-frontend/gtm-ecommerce";
import type { CookieBotType } from "~/hooks/cookiebot";
import { getCustomerId, getJWT } from "../helpers/auth";

let resolveCookiebot: (value: CookieBotType) => void;
const cookiebotPromise = new Promise<CookieBotType>((resolve) => {
	resolveCookiebot = resolve;
});

export const getCookiebotInstance = (): Promise<CookieBotType> => {
	if (process.env.NEXT_PUBLIC_ENVIRONMENT) {
		// mocked cookiebot for local development
		return Promise.resolve({
			consent: {
				marketing: true,
				necessary: true,
				preferences: true,
				stamp: true,
				statistics: true,
			},
			consented: true,
			declined: false,
			doNotTrack: false,
		} as unknown as CookieBotType);
	}

	return cookiebotPromise;
};

if (typeof window !== "undefined") {
	window.addEventListener("CookiebotOnConsentReady", () => {
		// update the useCookiebot hook when consent changes
		if (window.Cookiebot && resolveCookiebot) {
			resolveCookiebot(window.Cookiebot);
		}
	});
}

const getGtmClientId = () => {
	try {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
		return Number(window.ga.getAll()[0].get("clientId"));
	} catch {
		return 0;
	}
};

const hashString = async (message: string): Promise<string> => {
	// encode as UTF-8
	const msgBuffer = new TextEncoder().encode(message);

	// hash the message
	const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);

	// convert ArrayBuffer to Array
	const hashArray = Array.from(new Uint8Array(hashBuffer));

	// convert bytes to hex string
	return hashArray.map((b) => `00${b.toString(16)}`.slice(-2)).join("");
};

type UserDataProps = {
	email?: string;
};

export const userData = async ({ email }: UserDataProps = {}): Promise<UserData> => {
	const token = getJWT();
	const cookiebot = await getCookiebotInstance();

	return {
		client_id: getGtmClientId(),
		cookie_marketing: cookiebot.consent.marketing,
		cookie_necessary: cookiebot.consent.necessary,
		cookie_preferences: cookiebot.consent.preferences,
		cookie_statistics: cookiebot.consent.statistics,
		...(token?.authenticated
			? {
					is_authenticated: true,
					customer_id: getCustomerId(token),
					hashed_email: await hashString(token.email),
					customer_type: token.isBusinessCustomer ? "B2B" : "B2C",
				}
			: {
					is_authenticated: false,
					hashed_email: email ? await hashString(email) : null,
				}),
	};
};
