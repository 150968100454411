import type { EventData } from "@commerce-frontend/gtm-ecommerce/types";
import { sendGTMEvent as googleSendGTMEvent } from "@next/third-parties/google";

export * from "./device";
export * from "./ecommerce";
export * from "./location";
export * from "./search";
export * from "./user";

export type DeepPromise<T extends EventData> = { [P in keyof T]: Promise<T[P]> | T[P] };

export const sendGTMEvent = <T extends EventData>(data: DeepPromise<T>): void => {
	// Fire and forget method which loads any promised event data and fires the event when the data is ready
	const doWork = async () => {
		/**
		 * To allow for async tracking data, we allow data fields to be promises. We convert all
		 * properties of the data object into object entries, which we'll then await. When all
		 * entries have been resolved, we reconstitue them back into an object, resulting in
		 * the original object, but with all promised properties having been resolved
		 **/
		const keys = Object.keys(data) as (keyof typeof data)[];
		const entries = await Promise.all(keys.map((key) => Promise.all([key, data[key]])));
		const event = Object.fromEntries(entries);

		googleSendGTMEvent(event);
	};

	void doWork();
};
